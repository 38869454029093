@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

@import "./mathFunctions.scss";
@import "./hexagonal.scss";
@import "./components/Pricing/Pricing.scss";

$white-color: #ffffff;
$blue-color: #034275;
$pink-color: #ca006a;

body {
  font-family: "Montserrat", sans-serif;
}

h1 {
  color: $pink-color;
}

h2 {
  color: $pink-color;
}

h3 {
  color: $blue-color;
}

/* Para reescrever o container do bootstrap */
.container {
  max-width: 100vw !important;
}

.hero-parallax-top {
  position: absolute;
  width: 100vw;
}

.hero-parallax-bottom {
  position: absolute;
}

.App {
  text-align: center;
}

.HeaderPromotional {
  padding: 10px;
  max-width: 100vw;
}

.scrollToTopSVG {
  fill: #ca006a;
  cursor: pointer;
}

.knowMoreLink{
  cursor: pointer;
}

.scrollToTopSVG:hover {
  fill: rgb(255, 0, 134);
}

.subscribeButton {
  cursor: pointer;
}

.fake-link {
  color: $pink-color;
  text-decoration: underline;
  cursor: pointer;
}
